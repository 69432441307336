<template>
  <client-only>
    <line-banner />
    <template #fallback>
      <n-skeleton v-if="isShowLineBanner" class="line-banner w-full h-18"></n-skeleton>
    </template>
  </client-only>
</template>

<script setup lang="ts">
import { NSkeleton } from 'naive-ui'

import LineBanner from './line-banner.vue'

const { isShowLineBanner } = useLineBanner()
</script>

<style scoped>
.line-banner {
  /* DO NOT use display: none, it'll affects the same svg icon */
  @apply lg:opacity-0 lg:absolute lg:pointer-events-none;
}
</style>
